import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'index'
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/forget',
        name: 'Forget',
        component: () => import('../views/Forget.vue')
    },
    {
        path: '/explain',
        name: 'Explain',
        component: () => import('../views/Explain.vue')
    },
    {
        path: '/opinion',
        name: 'Opinion',
        component: () => import('../views/Opinion.vue')
    },
    {
        path: '/downloadApp',
        name: 'DownloadApp',
        component: () => import('../views/DownloadApp.vue')
    },
    {
        path: '/bind',
        name: 'Bind',
        component: () => import('../views/Bind.vue')
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../views/Index.vue')
    }

]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

