export default {
    register: '/user/register',// 注册
    login: '/user/login',// 登录
    getEmail: '/user/get_email_code',// 获取验证码
    logout: '/user/logout',// 退出登录
    findPassWord: '/user/find_back_password',// 重置密码
    affairAdd: '/affair/add',// 添加事务
    affairList: '/affair/list',//事务列表
    edit_status_level: '/affair/edit_status_level',// 事务等级操作
    otherGuide: '/other/guide',// 使用说明
    otherFeedback: '/other/feedback', // 建议反馈
    dataExport: '/jsondata/export',// 导出数据
    dataImport: '/jsondata/import',// 导入数据
    affairHandle: '/affair/handle',// 事务状态操作 1：删除事务；2：还原事务；3：完成事务
    editName: '/affair/edit_name',// 修改事务名称
    notice: '/notice/list',// 通知列表
    noticeDetail: '/notice/detail',// 通知详情
    noticeNum: 'notice/unread_num',// 未读的通知数
    messageRecord: 'message/record', // 私信列表
    messageSave: 'message/save',// 保存聊天记录
    messageList: 'message/list',// 获取聊天记录
    uploadImg: 'upload/img',//文件上传
    messageChange: 'message/change_unread',// 标记未读消息
    get_phone_code: '/user/get_phone_code',// 获取手机验证码
    wx_login_config: '/other/wx_login_config', //网页微信登录配置
    wx_login: 'user/wx_login', // 微信登录（PC版）
}
