import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { http } from "./request/http"
import api from "./request/api"
import 'element-ui/lib/theme-chalk/index.css'
import { Loading, Upload, Dialog, Button, Input, Form, FormItem, Pagination, Main, Image, Dropdown, DropdownMenu} from "element-ui"
import { message } from "./utils/Message"
import 'intro.js/introjs.css';
import VueLazyload from 'vue-lazyload'
Vue.prototype.$message = message
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)
Vue.use(Main)
Vue.use(Image)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service
// 修改 el-dialog 默认点击遮照为不关闭
Dialog.props.closeOnClickModal.default = false

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.api = api
Vue.use(VueLazyload, {
  preLoad: 1,
  // error: require('./assets/img/error.jpg'),
  loading: require('./assets/images/loding.gif'),
  attempt: 2,
})
// 解决跳转当前路由报错
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//判断是否登录 如果未登录跳转到登录页
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  if (to.path === '/index' || to.path === '/login' || to.path === '/register' || to.path === '/forget' || to.path === '/downloadApp'  || to.path === '/bind') return next()// 放行页面
  if (!localStorage.j_token) return next('/login')
  next()
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
