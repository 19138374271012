<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    data: function () {
        return {};
    },
    methods: {},
    mounted() {
        // 判断窗口大小
        window.onresize = () => {
            // console.log("---尺寸改变---");
            // clearTimeout(this.time);
            // console.log(document.body.clientWidth);
            // console.log(window.devicePixelRatio);
            // console.log(document.body.clientWidth * window.devicePixelRatio);
            // // 跳转到h5首页
            // document.body.clientWidth < 750
            //     ? this.$router.push("/mhome")
            //     : this.$router.push("/home");

            // 上注重体验 下注重性能

            // this.time = setTimeout(function () {
            //     console.log(document.body.clientWidth);
            //     console.log(window.devicePixelRatio);
            //     console.log(document.body.clientWidth * window.devicePixelRatio);
            //     if (document.body.clientWidth < 750) {
            //         // 跳转到h5首页
            //         that.$router.push("/mhome");
            //     } else {
            //         that.$router.push("/home");
            //     }
            // }, 500);
        };
    },
};
</script>

<style lang="less">
@import url(./assets/css/base.css);
@import url(./assets/iconfont/iconfont.css);
.introfont {
    font-size: 18px;
    color: #fff;
    background-color: #70ccff !important;
}
.intro_font {
    /*background-color: white !important;*/
    /*font-size: 16px !important;*/
}
.introjs-button {
    border: 1px solid #fff !important;
    background-color: #70ccff !important;
    color: #fff !important;
    background-image: none !important;
    line-height: 26px !important;
    text-shadow: none !important;
    padding: 0 12px !important;
}
.introjs-prevbutton {
    margin-right: 5px !important;
}
</style>
