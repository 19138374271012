import axios from 'axios'
import router from "../router";
import 'element-ui/lib/theme-chalk/index.css'
import { Message } from "element-ui";
import qs from 'qs'

let NODE_ENV_URL = ''
export const http = config => {
    if (process.env.NODE_ENV === 'production') {
        // 生产模式
        NODE_ENV_URL = window.location.protocol + '//' + window.location.host
    } else if (process.env.NODE_ENV === 'development') {
        // 开发模式
        NODE_ENV_URL = '/api'
    } else if (process.env.NODE_ENV == 'debug') {
        // 调试模式
    }
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: NODE_ENV_URL
            // timeout: 5000
        })
        // 请求拦截
        instance.interceptors.request.use(config => {
            config.method = 'post'
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            const token = localStorage.j_token
            const username = localStorage.j_username
            // 设置axios为form-data
            if (config.method === 'post') {
                // let data = qs.parse(config.data)
                config.data = qs.stringify({
                    token,
                    username,
                    ...config.data
                })
            } else if (config.method === 'get') {
                config.params = {
                    token,
                    username,
                    ...config.params
                }
            }
            return config
        })
        // 拦截响应
        instance.interceptors.response.use(data => {
            if (data.data.code === -9) {
                // 登录过期
                Message({
                    message: data.data.msg + '，请重新登录！',
                    center: true,
                    type: 'warning'
                })
                localStorage.removeItem('j_username')
                localStorage.removeItem('j_token')
                router.push('/login')
            }
            return data.data
        })
        instance(config).then(res => {
            resolve(res)
        }).catch(rej => {
            reject(rej)
        })
    })
}
